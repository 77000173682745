

import {
    defineComponent, reactive, onBeforeMount, onBeforeUnmount, computed, nextTick, onMounted,
} from 'vue';
import ItemPicker from '@/components/ItemPicker.vue';
import Item from '@/domain/Item';
import { ItemType } from '@/domain/enums/ItemType';
import { InventoryCategorySelectItem } from '@/domain/InventoryCategory';
import WarehouseMovementService from '@/modules/floortrak/services/WarehouseMovementService';
import { WarehouseMovementData } from '@/modules/floortrak/types/WarehouseMovementData';
import CoreStore from '@/store/CoreStore';
import useValidator from '@/validation/useValidator';
import { useNotification } from '@/composable/useNotifications';
import TagListener from '@/modules/floortrak/services/TagListener';
import { ItemTableData } from '@/domain/ItemTableData';
import InventoryGroupedDTO from '@/dtos/InventoryGroupedDTO';
import InventoryService from '@/services/InventoryService';
import TrackedItem from '@/domain/TrackedItem';
import WarehouseMovementTagScanHandler from '@/services/tag-scanning/scan-handler/WarehouseMovementTagScanHandler';
import Screen from '@/components/layout/Screen.vue';
import SmartTrakFooter from '@/components/SmartTrakFooter.vue';
import BRow from '@/components/bootstrap-library/BRow.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import Thumbnail from '@/components/Thumbnail.vue';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';
import SafetyInspectionQuestionnaire from '@/modules/floortrak/view/shared/components/SafetyInspectionQuestionnaire.vue';
import router from '@/router';
import FloorTrakRouteTypes from '@/modules/floortrak/router/types';
import SafetyInspectionType from '@/domain/enums/SafetyInspectionType';
import TrackedItemTagModal from '@/components/TrackedItemTagModal.vue';
import NumberInput from '@/components/inputs/NumberInput.vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import DropdownAutocompleteSingleSelect from '@/components/dropdown/DropdownAutocompleteSingleSelect.vue';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';

type State = {
    item: ItemTableData | null,
    itemQty: number | null
    inventory: Array<InventoryGroupedDTO>,
    movableInventoryCategories: Array<InventoryCategorySelectItem>
    puttingAway: boolean,
    ready: boolean,
    usingTags: boolean,
    showEquipmentInspection: boolean,
    showScannedTagsModal: boolean,
    trackedItems: Array<TrackedItem>,
    fromInventoryCategory: InventoryCategorySelectItem | null,
    toInventoryCategory: InventoryCategorySelectItem | null,
    componentKey: number,
}

export default defineComponent({
    name: 'warehouse-movement',
    components: {
        DropdownAutocompleteSingleSelect,
        BFormInput,
        NumberInput,
        SafetyInspectionQuestionnaire,
        Thumbnail,
        BButton,
        BCol,
        BRow,
        ItemPicker,
        Screen,
        SmartTrakFooter,
        TrackedItemTagModal,
    },
    setup() {
        const tagListener = new TagListener(searchItem);
        const { userLocation, forkliftCertified, certExpirationDate } = CoreStore.getInstance().profileStore;
        const { inventoryCategoryStore } = CoreStore.getInstance();
        const notification = useNotification();
        const { validateForm, validationResult } = useValidator<WarehouseMovementData>('warehouse-movement');
        const warehouseMovementService = new WarehouseMovementService();
        const inventoryService = new InventoryService();
        const { confirm } = useDialogBox();

        const state = reactive<State>({
            item: null,
            itemQty: null,
            inventory: [],
            movableInventoryCategories: inventoryCategoryStore.getMovableListItems(),
            puttingAway: false,
            ready: false,
            usingTags: false,
            showEquipmentInspection: true,
            showScannedTagsModal: false,
            trackedItems: [],
            fromInventoryCategory: null,
            toInventoryCategory: null,
            componentKey: 0,
        });

        function resetState() {
            state.item = null;
            state.itemQty = null;
            state.fromInventoryCategory = null;
            state.toInventoryCategory = null;
            state.inventory = [];
            state.usingTags = false;
            state.ready = false;
            nextTick(() => {
                state.ready = true;
            });
            state.trackedItems = [];
            state.componentKey += 1;
        }

        const savable = computed(() => state.toInventoryCategory && state.fromInventoryCategory && state.itemQty && !state.puttingAway);
        const selectedItemName = computed(() => state.item?.item.name ?? '');
        function inventoryOnHand(categoryId: number | null) {
            return state.item && categoryId ? state.inventory[state.item.item.id]?.getInventoryForCategoryId(categoryId, true) : null;
        }

        const fromInventoryOnHandForCategorySelected = computed(() => inventoryOnHand(state.fromInventoryCategory?.value ?? null));
        const toInventoryOnHandForCategorySelected = computed(() => inventoryOnHand(state.toInventoryCategory?.value ?? null));

        const fromInventoryCategoryAutofocus = computed(() => state.item && !fromInventoryOnHandForCategorySelected.value);
        const toInventoryCategoryAutofocus = computed(() => state.item && state.fromInventoryCategory && state.usingTags && !state.toInventoryCategory);

        function addItemToState(item: Item) {
            const newRecord = new ItemTableData(item);
            const inventory = state.inventory[item.id] || new InventoryGroupedDTO();
            newRecord.groupedInventory = inventory;
            state.item = newRecord;
        }

        async function updateCurrentInventoryCategoryCounts(itemId: number) {
            const inventory = await inventoryService.getItemInventoryGroupedByCategoryForSingleItem(itemId, userLocation.id);
            if (inventory) {
                const index = inventory.itemId;
                state.inventory[index] = inventory;
            }
        }

        async function addItem(data: { item: Item }) {
            if (data.item) {
                addItemToState(data.item);
                await updateCurrentInventoryCategoryCounts(data.item.id);
            } else {
                state.item = null;
            }
        }

        function addScannedBarcode(tag: TrackedItem) {
            state.trackedItems.push(tag);
            state.itemQty = state.itemQty! + 1;
            state.usingTags = true;
        }

        function isTagAlreadyInList(tag: TrackedItem) {
            return state.trackedItems.findIndex((trackedItem) => trackedItem.id === tag.id) > -1;
        }

        async function addTag(tag: TrackedItem) {
            const isTrackedItemSameAsSelectedItem = state.item && tag.item.id === state.item.item.id;

            if (!state.item || (isTrackedItemSameAsSelectedItem && !isTagAlreadyInList(tag))) {
                addItemToState(tag.item);
                await updateCurrentInventoryCategoryCounts(tag.itemId);
                addScannedBarcode(tag);
            } else {
                notification.showWarning(getTranslation('core.domain.scanItemMismatch'));
            }
        }

        async function searchItem(barcodes: Array<string>) {
            const tagHandler = new WarehouseMovementTagScanHandler(state.trackedItems, addTag);
            await tagHandler.performSearch(barcodes);
        }

        onBeforeMount(async () => {
            state.ready = true;
            tagListener.startListener();
        });

        onMounted(async () => {
            if (!forkliftCertified) {
                await confirm({
                title: getTitleCaseTranslation('core.validation.forkliftCertificationHeader'),
                message: getTranslation('core.validation.forkliftCertificationMissing'),
                vHtml: true,
                });

                router.push({ name: FloorTrakRouteTypes.HOME });
            }

            else if(!certExpirationDate || new Date(certExpirationDate) < new Date(Date.now())) {
                await confirm({
                title: getTitleCaseTranslation('core.validation.forkliftCertificationHeader'),
                message: getTranslation('core.validation.forkliftCertificationExpired'),
                vHtml: true,
                });

                router.push({ name: FloorTrakRouteTypes.HOME });
            }
        });

        onBeforeUnmount(() => {
            tagListener.stopListener();
        });

        async function moveInventoryAsync(data: WarehouseMovementData) {
            state.puttingAway = true;
            validateForm(data);
            if (validationResult.isValid) {
                const response = await warehouseMovementService.commitWarehouseMovement(data);
                if (response.success) {
                    notification.showSuccess(getTranslation('core.validation.inventorySuccessfullyMoved'));
                    resetState();
                }
            } else {
                validationResult.errorList.forEach((error) => notification.showError(error));
            }
            state.puttingAway = false;
        }

        async function submitClick() {
            const warehouseMovementData = {
                itemId: state.item!.item.id,
                locationId: userLocation.id,
                quantity: state.itemQty,
                fromCategoryId: state.fromInventoryCategory!.value,
                toCategoryId: state.toInventoryCategory!.value,
                trackedItems: state.trackedItems,
            } as unknown as WarehouseMovementData;

            await moveInventoryAsync(warehouseMovementData);
        }

        function cancelSafetyInspection() {
            router.push({ name: FloorTrakRouteTypes.HOME });
        }

        function removeTag(tag: TrackedItem) {
            const tagIndex = state.trackedItems.findIndex((item) => item.id === tag.id);
            state.trackedItems.splice(tagIndex, 1);
            state.itemQty = state.itemQty! - 1;
        }

        function selectFromInventoryCategory(data: any) {
            state.fromInventoryCategory = data;
        }

        function selectToInventoryCategory(data: any) {
            state.toInventoryCategory = data;
        }

        return {
            state,
            addItem,
            ItemType,
            submitClick,
            fromInventoryOnHandForCategorySelected,
            toInventoryOnHandForCategorySelected,
            savable,
            resetState,
            getTranslation,
            getTitleCaseTranslation,
            cancelSafetyInspection,
            SafetyInspectionType,
            removeTag,
            selectedItemName,
            selectFromInventoryCategory,
            selectToInventoryCategory,
            fromInventoryCategoryAutofocus,
            toInventoryCategoryAutofocus,
        };
    },
});
